import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
const VaraBilar = (props) => (
  <Layout>
    <Seo title='Trafikskolans bilar' />
    <PageHeader text='Trafikskolans bilar' />
    <div className='container'>
      <div className='row mt-5 justify-content-between'>
        <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-6 mb-2 '>
          <h3>Roliga och lättkörda Volkswagen Golf</h3>
          <p>
            Som elev hos oss är det viktigt att du känner dig bekväm med bilen du kommer utbilda dig i.
            Vi försöker hela tiden uppdatera vår fordonspark som ofta som möjligt för att bibehålla kvalité och säkerhet.
            Vi har i dag 14 st lättkörda Volkswagen Golf. Vi har både manuella och automatväxlade modeller.
          </p>
          <h3>Vår arpetsplats</h3>
          <p>
            Alla våra bilar är utrustade med extra speglar och dubbelkommando för att säkerställa eleven och lärarens säkerhet.
          </p>

        </div>

        <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 p-0 mt-md-5 mt-lg-3 mt-xl-3 mt-sm-1 mb-5 text-center'>
          {/* <img src={require('../../images/information/ovningsbil_1.jpg')} alt='Bild på trafikskolans bil' className='img-fluid rounded' /> */}
          <Img fluid={props.data.imageOne.childImageSharp.fluid} alt='Bild på trafikskolans bil' className='rounded' />

        </div>
      </div>
    </div>
  </Layout>
)

export default VaraBilar

export const pageQuery = graphql`
query {
  imageOne: file(relativePath: { eq: "information/ovningsbil_1.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 10000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
